import React, { FC } from 'react'
import * as pageUtils from './__details.utils'
import { REQUEST_CONFIGS_KEYS } from '@services/constants'
import { RadioList, SelectList } from '@utils/request'
import moment from 'moment'
import { formatStrapiText } from '@utils/methods'
import requestForm from '@components/requestForms/request.form'

const SelfReliefWaterCounterFormDetails: FC<pageUtils.Props> = ({
	pageAssets,
	request
}) => {
	const { authUser } = requestForm()

	const radioListYesNo: RadioList[] = [
		{
			label: pageAssets.request_form_select_yes,
			value: 'true',
			key: 'request_form_select_yes'
		},
		{
			label: pageAssets.request_form_select_no,
			value: 'false',
			key: 'request_form_select_no'
		}
	]

	const waterCounterStatusList: SelectList[] = [
		{
			label:
				pageAssets?.request_form_selfReliefWaterCounter_ownerWaterCounterStatusInput_inPossession_label,
			value: REQUEST_CONFIGS_KEYS.waterCounterStatus.inPossession,
			key: 'request_form_selfReliefWaterCounter_ownerWaterCounterStatusInput_inPossession_label'
		},
		{
			label:
				pageAssets?.request_form_selfReliefWaterCounter_ownerWaterCounterStatusInput_notInPossession_label,
			value: REQUEST_CONFIGS_KEYS.waterCounterStatus.notInPossession,
			key: 'request_form_selfReliefWaterCounter_ownerWaterCounterStatusInput_notInPossession_label'
		},
		{
			label:
				pageAssets?.request_form_selfReliefWaterCounter_ownerWaterCounterStatusInput_broken_label,
			value: REQUEST_CONFIGS_KEYS.waterCounterStatus.broken,
			key: 'request_form_selfReliefWaterCounter_ownerWaterCounterStatusInput_broken_label'
		},
		{
			label:
				pageAssets?.request_form_selfReliefWaterCounter_ownerWaterCounterStatusInput_unReadable_label,
			value: REQUEST_CONFIGS_KEYS.waterCounterStatus.unReadable,
			key: 'request_form_selfReliefWaterCounter_ownerWaterCounterStatusInput_unReadable_label'
		},
		{
			label:
				pageAssets?.request_form_selfReliefWaterCounter_ownerWaterCounterStatusInput_unPlugged_label,
			value: REQUEST_CONFIGS_KEYS.waterCounterStatus.unPlugged,
			key: 'request_form_selfReliefWaterCounter_ownerWaterCounterStatusInput_unPlugged_label'
		}
	]

	const getLabelFromList = (list: SelectList[], value) => {
		return list?.find((element) => element.value == value?.toString())?.label
	}

	return (
		<>
			<h4 className={pageUtils.classes.h4}>
				{pageAssets?.request_form_selfReliefWaterCounter_firstSubstep_title}
			</h4>

			<>
				<p className={pageUtils.classes.paragraph}>
					<strong> {pageAssets.label_address} </strong>
					<br />
					{request.incidentForm?.sameAddressAsApplicant
						? authUser?.profile?.address.address
						: request.incidentForm?.declareAddress}
				</p>

				{(request.incidentForm?.declareAppartment ||
					request.incidentForm?.sameAddressAsApplicant) && (
					<p className={pageUtils.classes.paragraph}>
						<strong>
							{' '}
							{formatStrapiText(pageAssets?.label_apartment_office)}{' '}
						</strong>
						<br />
						{request.incidentForm?.sameAddressAsApplicant
							? authUser?.profile?.address.apartment
							: request.incidentForm?.declareAppartment}
					</p>
				)}
			</>

			<p className={pageUtils.classes.paragraph}>
				<strong>
					{
						pageAssets.request_form_selfReliefWaterCounter_ownerWaterCounterStatusInput_label
					}
				</strong>
				<br />
				{getLabelFromList(
					waterCounterStatusList,
					request.incidentForm?.ownerWaterCounterStatus
				)}
			</p>

			{request.incidentForm?.ownerWaterCounterStatus ==
				REQUEST_CONFIGS_KEYS.waterCounterStatus.inPossession && (
				<>
					<p className={pageUtils.classes.paragraph}>
						<strong>
							{
								pageAssets.request_form_selfReliefWaterCounter_counterReadingDate_label
							}
						</strong>
						<br />
						{pageUtils.getLocaleDate(request.incidentForm?.counterReadingDate!)}
					</p>

					<p className={pageUtils.classes.paragraph}>
						<strong>
							{
								pageAssets.request_form_selfReliefWaterCounter_counterReadingValue_label
							}
						</strong>
						<br />
						{request.incidentForm?.counterReadingValue}
					</p>
				</>
			)}

			{request.incidentForm?.counterSerialNumber && (
				<p className={pageUtils.classes.paragraph}>
					<strong>
						{
							pageAssets.request_form_selfReliefWaterCounter_counterSerialNumber_label
						}
					</strong>
					<br />
					{request.incidentForm?.counterSerialNumber}
				</p>
			)}

			{request.incidentForm?.occupantNumber?.toString() != '' && (
				<p className={pageUtils.classes.paragraph}>
					<strong>
						{
							pageAssets.request_form_selfReliefWaterCounter_occupantNumber_label
						}
					</strong>
					<br />
					{request.incidentForm?.occupantNumber}
				</p>
			)}

			{/* {request.incidentForm?.registerForEmail?.toString() != 'false' && (
				<p>
					<strong>
						{
							pageAssets?.request_form_selfReliefWaterCounter_registerForEmail_label
						}
					</strong>
				</p>
			)} */}
		</>
	)
}

export default SelfReliefWaterCounterFormDetails
